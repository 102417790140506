import React from 'react'
import styled from 'styled-components'
import flagImg from '../assets/Flag.svg'

interface RedeemExhibtProps {
  className?: string;
  avatar?: string;
  name: string;
  price: number;
  desc: string;
}

interface RedeemExhibtStates {}

class RedeemExhibt extends React.Component<RedeemExhibtProps, RedeemExhibtStates> {
  render () {
    const { className, name, price, desc, avatar } = this.props
    return (
      <div className={className}>
        <div className="redeemable">
          <div className="redeem-image-wrapper">
            <img className="redeem-image" src={avatar || flagImg} alt=""/>
          </div>
          <div className="desc-container">
            <div className="desc-header">
              <div className="desc-title">{name}</div>
              <span className="get-btn">Get this</span>
            </div>
            <div className="desc-price">
              <span className="desc-small">$</span>
              <span className="desc-price-value">{price}</span>
              <span className="desc-small">credit(s).</span>
            </div>
            <div className="desc-func">{desc}</div>
          </div>
        </div>
      </div>
    )
  }
}

const StyledRedeemExhibt = styled(RedeemExhibt)`
.redeemable {
  margin-top: 15px;
  height: 100px;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 2px 5px -2px #9E9E9E;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  color: #555555;
}
.redeem-image-wrapper {
  width: 70px;
  padding-right: 15px;
  border-right: dashed 2px #e3e3e3;
  flex-shrink: 0;
}
.redeem-image {
  width: 100%;
}
.desc-container {
  padding-left: 15px;
  flex-grow: 1;
}
.desc-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.get-btn {
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
}
.desc-title {
  text-transform: uppercase;
  font-weight: 600;
}
.desc-price {
  font-weight: 900;
  font-size: 30px;
}
.desc-price > * + * {
  margin-left: 5px;
}
.desc-func {
  font-size: 11px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}
.desc-small {
  font-size: 13px;
}
`

export default StyledRedeemExhibt
