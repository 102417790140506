import React from 'react'
import {
  BottomNavigation,
  BottomNavigationAction
} from '@material-ui/core'
import { People as UserIcon, Archive as ArchiveIcon, Flag as FlagIcon } from '@material-ui/icons'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { withTranslation, WithTranslation } from 'react-i18next'

interface BottomNavProps extends RouteComponentProps, WithTranslation {
  className?: string;
}

interface BottomNavState {
  currentTab: string;
}

class BottomNav extends React.Component<BottomNavProps, BottomNavState> {
  constructor(props: BottomNavProps) {
    super(props)
    this.state = {
      currentTab: 'achivement'
    }
    this.handleTabChange = this.handleTabChange.bind(this)
  }
  handleTabChange(_: any, tab: string) {
    this.setState({ currentTab: tab })
    this.props.history.push(tab)
  }
  render() {
    const { className, t } = this.props
    const { currentTab } = this.state
    return (
      <BottomNavigation className={className} value={currentTab} onChange={this.handleTabChange} showLabels>
        <BottomNavigationAction value="achivement" label={t('bottom-bar-achievement')} icon={<ArchiveIcon />} />
        <BottomNavigationAction value="flag" label={t('bottom-bar-flag')} icon={<FlagIcon />} />
        <BottomNavigationAction value="profile" label={t('bottom-bar-profile')} icon={<UserIcon />} />
      </BottomNavigation>
    )
  }
}
const StyledBottomNav = styled(BottomNav)`
/* to make z-index work properly */
transform: scale(1);
border-top: solid 1px #e3e3e3;
box-sizing: border-box;
`
export default withRouter(
  withTranslation()(
    StyledBottomNav
  )
)
