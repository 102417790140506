import React from 'react'
import styled from 'styled-components'
import { getUserCreditRecord } from '../api/userAPI'
import dayjs from 'dayjs'
import BScroll from '@better-scroll/core'
import PullDown from '@better-scroll/pull-down'
BScroll.use(PullDown)

interface CreditRecordItemOptions {
  purpose: string;
  amount: number;
  create_time: Date;
}

interface CreditRecordProps {
  className?: string;
}

interface CreditRecordStates {
  records: CreditRecordItemOptions[];
  isPulling: boolean;
  pullDownHandle: any;
}

class CreditRecord extends React.Component<CreditRecordProps, CreditRecordStates> {
  
  constructor(props: CreditRecordProps) {
    super(props)
    this.state = {
      records: [],
      isPulling: false,
      pullDownHandle: null
    }
  }

  handlePullingDown = async () => {
    this.setState({ isPulling: true })
    await this.requestData()
    this.handleFinishPulldown()
  }

  handleFinishPulldown = () => {
    this.setState({ isPulling: false })
    this.state.pullDownHandle.finishPullDown()
  }

  requestData = async () => {
    const { data } = await getUserCreditRecord()
    this.setState({ records: data })
  }

  async componentDidMount() {
    await this.requestData()
    const scroll = new BScroll('.b-scroll-container', {
      scrollY: true,
      pullDownRefresh: true
    })
    scroll.on('pullingDown', this.handlePullingDown)
    this.setState({ pullDownHandle: scroll })
  }

  render () {
    const { className } = this.props
    const { isPulling } = this.state
    const recordsElem = this.state.records.map((rec, idx) => {
      return <CreditRecordItem opt={rec} key={idx} />
    })
    const requestingHint = isPulling ? <div className="requesting">请稍后 ...</div> : null
    return (
      <div className={className + ' b-scroll-container'}>
        {requestingHint}
        <div className="scroll-wrapper">
          {recordsElem}
        </div>
      </div>
    )
  }
}

function InnerCreditRecordItem (props: { opt: CreditRecordItemOptions, className?: string }) {
  const { create_time, amount } = props.opt
  return (
    <div className={props.className}>
      <section className='title'>
        <span>{ props.opt.purpose }</span>
        <span className={amount < 0 ? "concurency neg" : "concurency"}>{ amount >=0 ? '+' : '' }{ amount }</span>
      </section>
      <section className='date'>{ dayjs(create_time).format('YYYY-MM-DD H:mm:ss') }</section>
    </div>
  )
}
const CreditRecordItem = styled(InnerCreditRecordItem)`
  background: white;
  border-radius: 5px;
  padding: 10px;
  & + & {
    margin-top: 10px;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .concurency {
    color: green;
    font-weight: 600;
    font-size: 20px;
    &.neg {
      color: red;
    }
  }
  .date {
    margin-top: 5px;
    color: gray;
    font-size: 12px;
  }
`

const StyledCreditRecord = styled(CreditRecord)`
height: calc(100% - 56px);
padding: 0 17px;
box-sizing: border-box;
position: relative;
overflow: hidden;
.scroll-wrapper {
  position: relative;
  padding-top: 17px;
}
.requesting {
  position: absolute;
  padding: 17px;
  text-align: center;
  width: calc(100% - 17px - 17px);
  box-sizing: border-box;
}
`
export default StyledCreditRecord
