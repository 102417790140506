import React from 'react'
import styled from 'styled-components'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'

interface DeleteConfirmDialogProps {
  className?: string;
  onConfirm?: () => void;
}
interface DeleteConfirmDialogStates {
  isOpen?: boolean;
  deleteId?: number;
  deleteTitle?: string;
}

export class DeleteConfirmDialog extends React.Component<DeleteConfirmDialogProps, DeleteConfirmDialogStates> {
  constructor(props: DeleteConfirmDialogProps) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  toggle = () => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  private confirm = async () => {
    const { onConfirm } = this.props
    onConfirm?.()
  }

  setPayload = (payload: DeleteConfirmDialogStates) => {
    this.setState(payload)
  }

  render() {
    const { className } = this.props
    const { isOpen, deleteTitle } = this.state
    return (
      <Dialog className={className} open={isOpen || false} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <span style={{ marginRight: '10px' }} role='img' aria-label='king'>🚨</span>
          危险</DialogTitle>
        <DialogContent>
          <DialogContentText>
            确认删除【{deleteTitle}】
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="footer-actions">
            <section>
              <Button variant="outlined" style={{ marginRight: '5px' }} onClick={this.toggle}>取消</Button>
              <Button variant="contained" color="secondary" onClick={this.confirm}>确认</Button>
            </section>
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

const StyledDeleteConfirmDialog = styled(DeleteConfirmDialog)`
`

export default StyledDeleteConfirmDialog
