import { AnyAction } from "redux";
import { SET_TOTAL_ACHIEVEMENT } from "./actionTypes";

const initialAchievementState = {
  totalCount: 0
}

const achievementReducer = (state = initialAchievementState, action: AnyAction) => {
  const { type, payload } = action
  switch (type) {
    case SET_TOTAL_ACHIEVEMENT: {
      return { ...state, totalCount: payload }
    }
    default: {
      return state
    }
  }
}

export default achievementReducer
