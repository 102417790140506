import React from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import BottomNav from '../components/BottomNav'
import Profile from './Profile'
import Flag from './Flag'
import Achivement from './Achivement'
import CreditRecord from './CreditRecord'
import Setting from './Setting'
import Message from './Message'
import Redeem from './Redeem'

interface AppLayoutProps {
  className?: string;
}

class AppLayout extends React.Component<AppLayoutProps> {
  render() {
    const { className } = this.props
    return (
      <div className={className}>
        <Route path="/app/achivement">
          <Achivement />
        </Route>
        <Route path="/app/flag">
          <Flag />
        </Route>
        <Route path="/app/profile">
          <Profile />
        </Route>
        <Route path="/app/credit-record">
          <CreditRecord />
        </Route>
        <Route path="/app/setting">
          <Setting />
        </Route>
        <Route path="/app/redeem">
          <Redeem />
        </Route>
        <Route path="/app/message">
          <Message />
        </Route>
        <BottomNav></BottomNav>
      </div>
    )
  }
}

const StyledAppLayout = styled(AppLayout)`
box-sizing: border-box;
height: calc(100% - 56px);
background: #F7F8F9;
`
export default StyledAppLayout
