import React from 'react'
import './App.css'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import {
  ThemeProvider,
  createMuiTheme,
  Snackbar
} from '@material-ui/core'
import { deepOrange } from '@material-ui/core/colors'
import AppLayout from './containers/AppLayout'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import Login from './containers/Login'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
// import { Alert } from '@material-ui/lab'
import { dismissGlobalNavbar } from './store/reducers/system/actions'

interface AppProps {
  className?: string;
}

interface AppState {
  currentTab: string;
  snackbarOption: any;
}
const theme = createMuiTheme({
  palette: {
    primary: deepOrange
  }
})

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props)
    this.state = {
      currentTab: '',
      snackbarOption: { message: '' }
    }
  }

  handleCloseMsg = () => {
    store.dispatch(dismissGlobalNavbar())
  }

  componentDidMount () {
    store.subscribe(() => {
      const { snackbarOption } = store.getState().system
      this.setState({ snackbarOption })
    })
  }

  render() {    
    const { snackbarOption } = this.state
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <div className={`App ${this.props.className}`}>
              <Snackbar message={snackbarOption.message} 
                onClose={this.handleCloseMsg}
                autoHideDuration={1000} 
                open={snackbarOption.message !== ''} 
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
              <div className='app-header' style={{ background: 'white' }}>
                <IconButton edge="start" color="inherit" aria-label="menu">
                  <FingerprintIcon style={{ color: 'red' }} /> ICAN
                </IconButton>
              </div>
              <Router>
                <Switch>
                  <Route path="/app">
                    <AppLayout />
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/" exact>
                    <Redirect to="/app/achivement" />
                  </Route>
                </Switch>
              </Router>
            </div>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}

const StyledApp = styled(App)`
height: 100%;
.app-header {
  height: 56px;
  padding: 0 17px;
}
`

export default StyledApp
