import React from 'react'
import styled from 'styled-components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface EntryButtonProps {
  className?: string;
  title: string;
  icon?: any;
  displayOnly?: boolean;
  labelCenter?: boolean;
  extra?: any;
  onClick?: () => void;
}

interface EntryButtonStates { }

class EntryButton extends React.Component<EntryButtonProps, EntryButtonStates> {
  handleClick = () => {
    this.props.onClick?.()
  }
  render() {
    const { className, title, icon, displayOnly, labelCenter, extra } = this.props
    
    const iconElem = icon ? <span style={{ marginRight: '10px' }}>{icon}</span> : null
    const clickHintElem = displayOnly ? null : <ChevronRightIcon />
    return (
      <div className={className} onClick={this.handleClick}>
        <span className="icon">
          {iconElem}
        </span>
        <div className={labelCenter ? 'name label-center' : 'name'}>{title}</div>
        <div className="extra">{extra}</div>
        <section className="right">
          { clickHintElem }
        </section>
        <div className="bottom-line"></div>
      </div>
    )
  }
}

const StyledEntryButton = styled(EntryButton)`
padding: 0 17px;
background: white;
height: 50px;
display: flex;
align-items: center;
justify-content: space-between;
position: relative;
box-sizing: border-box;
&:active {
  background: #e3e3e3;
}
&:last-child .bottom-line {
  /* hide bottom of last entrybutton */
  display: none;
}
.name {
  flex-grow: 1;
  &.label-center {
    text-align: center;
  }
}
.extra {
  color: gray;
}
.bottom-line {
  position: absolute;
  bottom: 0;
  left: calc(17px + 30px);
  height: 1px;
  width: calc(100% - 17px - 30px);
  background: #e3e3e3;
}
`

export default StyledEntryButton
