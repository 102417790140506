import React from 'react'
import styled from 'styled-components'
import EmptyImage from '../assets/empty.svg'
import BScroll from '@better-scroll/core'
import PullDown from '@better-scroll/pull-down'
BScroll.use(PullDown)

interface ListProps {
  className?: string;
  renderFn: (data: any) => JSX.Element;
  requestDataFn: () => Promise<any>;
}
interface ListStates { 
  listData: any[];
  pullDownHandle: any;
  isRequesting: boolean;
}

export class List extends React.Component<ListProps, ListStates> {
  constructor(props: ListProps) {
    super(props)
    this.state = {
      listData: [],
      pullDownHandle: null,
      isRequesting: false
    }
  }
  async componentDidMount() {
    await this.requestData()
    const scroll = new BScroll('.scroll-container', {
      scrollY: true,
      click: true,
      pullDownRefresh: { threshold: 30 }
    })
    scroll.on('pullingDown', this.handlePullingDown)
    this.setState({ pullDownHandle: scroll })
  }

  requestData = async () => {
    const { requestDataFn } = this.props
    let data
    this.setState({ isRequesting: true })
    try {
      data = (await requestDataFn()) || []
    } catch (e) {
      data = []
    }
    this.setState({ listData: data, isRequesting: false })
  }

  handlePullingDown = async () => {
    await this.requestData()
    this.handleFinishPulldown()
  }

  handleFinishPulldown = () => {
    this.state.pullDownHandle.finishPullDown()
  }

  render() {
    const { className, renderFn } = this.props
    const { listData, isRequesting } = this.state
    const listItems = listData.map(renderFn)
    const requestingHint = isRequesting ? <div className="hint requesting-hint">Requesting...</div> : null
    const releaseHint = !isRequesting ? <div className="hint release-hint">Release to load</div> : null
    const emptyIndicator = (listData.length === 0 && !isRequesting) ?
      <div className="empty-indicator">
        <img src={EmptyImage} alt="" width="200" />
        <span className="empty-hint-text">No data</span>
      </div> : null
    return (
      <div className={className + ' scroll-container'}>
        {requestingHint}
        <div className="item-wrapper">
          { emptyIndicator }
          { releaseHint }
          { listItems }
        </div>
      </div>)
  }
}

const StyledList = styled(List)`
box-sizing: border-box;
height: 100%;
overflow: hidden;
.requesting-hint {
  position: absolute;
}
.hint {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-wrapper {
  position: relative;
  padding-top: 15px;
}
.release-hint {
  position: absolute;
  top: -30px;
}
.empty-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.empty-hint-text {
  margin-top: 20px;
}
`

export default StyledList
