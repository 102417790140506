import { AnyAction } from 'redux'
import { SHOW_GLOBAL_SNACKBAR, DISMISS_GLOBAL_SNACKBAR, CHANGE_SYSTEM_LANGUAGE } from './actionTypes'

export interface ReduxAction extends AnyAction {
  type: string;
  payload?: any;
  [key: string]: any;
}

const systemInitialState = {
  lang: 'en',
  snackbarOption: {
    message: ''
  }
}
const systemReducer = (state = systemInitialState, action: ReduxAction) => {
  const { type, payload } = action
  switch(type) {
    case SHOW_GLOBAL_SNACKBAR: {
      return { ...state, snackbarOption: payload }
    }
    case DISMISS_GLOBAL_SNACKBAR: {
      return { ...state, snackbarOption: { message: '' } }
    }
    case CHANGE_SYSTEM_LANGUAGE: {
      return { ...state, lang: payload }
    }
    default: {
      return state
    }
  }
}

export default systemReducer
