import { SHOW_GLOBAL_SNACKBAR, DISMISS_GLOBAL_SNACKBAR, CHANGE_SYSTEM_LANGUAGE } from "./actionTypes";

export interface GlobalNavbarOptions {
  isOpen?: boolean;
  message: string;
}
export function showGlobalNavbar(options: GlobalNavbarOptions) {
  return { type: SHOW_GLOBAL_SNACKBAR, payload: options }
}

export function dismissGlobalNavbar() {
  return { type: DISMISS_GLOBAL_SNACKBAR }
}

export function changeLanguage(lang: string) {
  return { type: CHANGE_SYSTEM_LANGUAGE, payload: lang }
}
