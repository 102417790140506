import React, { createRef } from 'react'
import styled from 'styled-components'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core'
import { AchivementData } from './Achivement'
import { DeleteConfirmDialog } from './DeleteConfirmDialog'
import { deleteAchievementItem } from '../api/achievementAPI'

interface ViewAchievementDialogProps {
  className?: string;
  isOpen?: boolean;
  onDismiss?: () => void;
  data?: AchivementData;
}
interface ViewAchievementDialogStates { }

class ViewAchievementDialog extends React.Component<ViewAchievementDialogProps, ViewAchievementDialogStates> {

  private deleteDialogRef: React.RefObject<DeleteConfirmDialog>

  constructor (props: ViewAchievementDialogProps) {
    super(props)
    this.deleteDialogRef = createRef()
    this.handleDismiss = this.handleDismiss.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }
  handleDismiss() {
    this.props.onDismiss?.()
  }
  handleDelete = async () => {
    const { id } = this.props.data!
    try {
      await deleteAchievementItem(id)
      this.handleDismiss()
    } finally {}
  }
  showDeleteConfirm = () => {
    const { title } = this.props.data!
    const dialog = this.deleteDialogRef.current
    dialog?.setPayload({ deleteTitle: title })
    dialog?.toggle()
  }
  render() {
    const { isOpen, data, className } = this.props
    return (
      <Dialog className={className} fullScreen open={isOpen || false} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <span style={{ marginRight: '10px' }} role='img' aria-label='king'>👑</span>
          查看成就</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3>{ data?.title }</h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="footer-actions-wrapper">
          <div className="footer-actions">
            <Button variant="contained" color="secondary" onClick={this.showDeleteConfirm}>删除</Button>
            <section>
              <Button onClick={this.handleDismiss} variant="outlined" style={{ marginRight: '5px' }}>取消</Button>
              <Button variant="contained" color="primary" onClick={this.handleDismiss}>确认</Button>
            </section>
          </div>
        </DialogActions>
        <DeleteConfirmDialog onConfirm={this.handleDelete} ref={this.deleteDialogRef} />
      </Dialog>
    )
  }
}

const StyledViewAchievementDialog = styled(ViewAchievementDialog)`
.footer-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footer-actions-wrapper {
  margin-bottom: env(safe-area-inset-bottom);
}
`
export default StyledViewAchievementDialog
