import React from 'react'
import styled from 'styled-components'
import List from '../components/List'

interface FlagProps {
  className?: string;
}

interface FlagStates { }

class Flag extends React.Component<FlagProps, FlagStates> {
  requestData = async () => {
  }
  render () {
    const { className } = this.props
    const renderFn = () => <div></div>
    return (
      <div className={className}>
        <List renderFn={renderFn} requestDataFn={this.requestData}></List>
      </div>
    )
  }
}

const StyledFlag = styled(Flag)`
height: calc(100% - 56px);
/* border: solid 1px; */
box-sizing: border-box;
`

export default StyledFlag
