import React, { ChangeEvent } from 'react'
import { Button, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import { login } from '../api/userAPI'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { setUserInfo, UserInfo } from '../store/reducers/user/actions'
import { showGlobalNavbar } from '../store/reducers/system/actions'

interface StoreProps {
  setLoginUserInfo: (userInfo: UserInfo) => void;
  showMessage: (msg: string) => void
}

interface LoginProps extends RouteComponentProps, StoreProps {
  className?: string;
}
interface LoginStates {
  logging: boolean;
  username: string;
  password: string;
  loginError: string;
}

class Login extends React.Component<LoginProps, LoginStates> {
  constructor(props: LoginProps) {
    super(props)
    this.state = {
      logging: false,
      username: '',
      password: '',
      loginError: ''
    }
  }

  handleLogin = async () => {
    const { history, setLoginUserInfo, showMessage } = this.props
    const { username, password } = this.state
    this.setState({ logging: true })
    showMessage('登陆中')
    try {
      const res = await login(username, password) as unknown as UserInfo
      localStorage.setItem('access_token', res.access_token!)
      setLoginUserInfo(res)
      showMessage('登陆成功')
      history.push('/')
    } catch (e) {
      this.setState({ loginError: '账户名或密码错误', logging: false })
    }
  }
  handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: event.target.value })
  }

  handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value })
  }

  render() {
    const { className } = this.props
    const { logging, loginError } = this.state
    const loginErrorComp = loginError ? <Alert severity="error" variant="filled">{loginError}</Alert> : null
    return (
      <div className={className}>
        {loginErrorComp}
        Login
        <div className="wrapper">
          <TextField
            data-type='username'
            onChange={this.handleUsernameChange}
            className='form-item'
            label="用户名"
            variant="outlined"
          />
          <TextField
            data-type='password'
            onChange={this.handlePasswordChange}
            className='form-item'
            label="密码"
            type="password"
            autoComplete="current-password"
            variant="outlined"
          />
          <Button disabled={logging} className='login-btn' onClick={this.handleLogin} variant='contained' color="primary">
            {logging ? '登陆中' : '登陆'}
          </Button>
        </div>
      </div>
    )
  }
}

const StyledLogin = styled(Login)`
padding: 17px;
.form-item {
  width: 100%;
}
.form-item + .form-item {
  margin-top: 10px;
}
.login-btn {
  margin-top: 10px;
  width: 100%;
}
.wrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
`
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setLoginUserInfo: (userInfo: UserInfo) => {
      dispatch(setUserInfo(userInfo))
    },
    showMessage: (message :string) => dispatch(showGlobalNavbar({ message }))
  }
}
export default connect(null, mapDispatchToProps)(withRouter(StyledLogin))
