import { SET_USERINFO, LOG_OUT } from "./actionTypes";
import { AnyAction } from "redux";

export interface UserInfo {
  username: string;
  nickname: string;
  userid: number;
  avatar: string;
  desc: string;
  credit: number;
  create_time: string;
  access_token?: string;
}
export function setUserInfo (userInfo: UserInfo): AnyAction {
  return { type: SET_USERINFO, payload: userInfo }
}

export function logout (): AnyAction {
  return { type: LOG_OUT }
}
