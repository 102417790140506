import { AnyAction } from 'redux'
import { SET_USERINFO, LOG_OUT } from './actionTypes'

export interface ReduxAction extends AnyAction {
  type: string;
  payload?: any;
  [key: string]: any;
}

const userInitialState = {
  userInfo: null,
  globalNavbar: null
}
const userReducer = (state = userInitialState, action: ReduxAction) => {
  const { type, payload } = action
  switch(type) {
    case SET_USERINFO: {
      return { ...state, userInfo: payload }
    }
    case LOG_OUT: {
      localStorage.removeItem('access_token')
      return { ...state, userInfo: null }
    }
    default: {
      return state
    }
  }
}

export default userReducer
