import React from 'react'
import styled from 'styled-components'
import { withRouter, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { UserInfo, logout, setUserInfo } from '../store/reducers/user/actions'
import EntryButton from '../components/EntryButton'
import SettingsIcon from '@material-ui/icons/Settings';
import { Dispatch } from 'redux'
import { showGlobalNavbar } from '../store/reducers/system/actions'
import AssessmentIcon from '@material-ui/icons/Assessment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import DescriptionIcon from '@material-ui/icons/Description';
import dayjs from 'dayjs'
import { getUserInfo } from '../api/userAPI'
import defaultAvatar from '../assets/default-avatar.jpg'
import { withTranslation, WithTranslation } from 'react-i18next'

interface StoreProps {
  userInfo: UserInfo,
  handleLogout: () => void;
  showMessage: (msg: string) => void;
  setLoginUserInfo: (userinfo: UserInfo) => void;
}
interface ProfileProps extends RouteComponentProps, StoreProps, WithTranslation {
  className?: string;
}
interface ProfileStates { }

class Profile extends React.Component<ProfileProps, ProfileStates> {

  goto = (path: string) => {
    const { history } = this.props
    history.push(path)
  }

  handleLogout = () => {
    const { handleLogout, showMessage } = this.props
    handleLogout()
    showMessage('Bye ~')
  }

  componentDidMount = async () => {
    const res = await getUserInfo()
    this.props.setLoginUserInfo(res as unknown as UserInfo)
  }

  render() {
    const { className, userInfo, t } = this.props
    const userpanel = userInfo ?
      <UserPanelLoggedIn userInfo={userInfo} /> :
      <UserPanelNotLoggedIn onClick={this.goto.bind(this, '/login')} />
    const entries = userInfo ? [
      <EntryButton title={t('entry-report')} icon={<AssessmentIcon />} />,
      <EntryButton title={t('entry-redeem')} icon={<ShoppingCartIcon />} onClick={this.goto.bind(this, '/app/redeem')} extra="新版兑换上线" />,
      <EntryButton title={t('entry-credit-record')} icon={<DescriptionIcon />} onClick={this.goto.bind(this, '/app/credit-record')} />,
      <EntryButton title={t('entry-message')} icon={<QuestionAnswerIcon />} onClick={this.goto.bind(this, '/app/message')} />
    ] : [
        <EntryButton title='登陆' icon={<ExitToAppIcon />} onClick={this.goto.bind(this, '/login')} />
      ]

    const existTime = userInfo ? <div className="entry-wrapper">
      <EntryButton displayOnly title={`已经在星海漂流了 ${dayjs(userInfo?.create_time).fromNow(true)}`} />
    </div> : null

    const loginBtn = userInfo ? <div className="entry-wrapper">
      <EntryButton labelCenter displayOnly title={t('entry-logout')} onClick={this.handleLogout} />
  </div> : null
    return (
      <div className={className}>
        {userpanel}
        {existTime}
        <div className="entry-wrapper">
          {entries}
          <EntryButton title={t('entry-setting')} icon={<SettingsIcon />} onClick={this.goto.bind(this, '/app/setting')} />
        </div>
        {loginBtn}
      </div>
    )
  }
}

function UserPanelLoggedIn(props: any) {
  const { nickname, avatar, desc, credit } = props.userInfo
  return (
    <div className="user-info">
      <div className="info-line-container">
        <div className="user-name">{nickname}</div>
        <div className="user-desc">{desc || '-'}</div>
        <div className="assets">
          <span style={{ display: 'flex', alignItems: 'center' }}><MonetizationOnIcon className="coin-icon" /> {credit}</span>
        </div>
      </div>
      <img src={avatar} alt='avatar' className="avatar"></img>
    </div>
  )
}

function UserPanelNotLoggedIn(props: any) {
  const { onClick } = props
  return (
    <div className="user-info" onClick={onClick}>
      <div>
        <div className="user-name">UnAuthed</div>
        <div className="user-desc">Please click to login</div>
      </div>
      <img src={defaultAvatar} alt='avatar' className="avatar"></img>
    </div>
  )
}

const StyledProfile = styled(Profile)`
height: calc(100% - 56px);
.entry-wrapper {
  padding-top: 15px;
}
.user-info {
  background: white;
  height: 200px;
  padding: 0 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1101;
}
.info-line-container {
  & > * + * {
    margin-top: 10px;
  }
}
.coin-icon {
  margin-right: 5px;
  font-size: 16px;
}
.avatar {
  width: 100px;
  height: 100px;
  border: solid 1px #e3e3e3;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}
.user-name {
  font-size: 32px;
  font-weight: 900;
}
.user-desc {
  color: gray;
}
`

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.user.userInfo
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    handleLogout: () => dispatch(logout()),
    setLoginUserInfo: (userInfo: UserInfo) => {
      dispatch(setUserInfo(userInfo))
    },
    showMessage: (message: string) => dispatch(showGlobalNavbar({ message }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(StyledProfile)))
