import React from 'react'
import styled from 'styled-components'
import List from '../components/List'
import { getMessageList } from '../api/messageAPI'
import dayjs from 'dayjs'

interface MessageProps {
  className?: string;
}
interface MessageStates {
  listData: any[];
}

class Message extends React.Component<MessageProps, MessageStates> {
  requestData = async () => {
    const { data } = await getMessageList()
    return data
  }
  render() {
    const { className } = this.props
    const renderFn = (data: any) => <MessageItem data={data} />
    return (
      <div className={className}>
        <List renderFn={renderFn} requestDataFn={this.requestData}></List>
      </div>)
  }
}

function _MessageItem(props: any) {
  const { className, data } = props
  return (
    <div className={className}>
      <span className="title">{data.title}</span>
      <section className="message-meta">
        <div>
          <span className="from">From: </span><span className="from-user">{data.fromUserNickname}</span>
        </div>
        <div className="date">{ dayjs(data.create_time).format('YYYY-MM-DD H:mm:ss') }</div>
      </section>
      <section className="body">
        {data.content}
      </section>
    </div>
  )
}
const MessageItem = styled(_MessageItem)`
background: white;
padding: 10px;
margin: 0 10px;
border-radius: 5px;
& + & {
  margin-top: 10px;
}
.title {
  font-weight: 900;
}
.message-meta {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
span.from {
  font-size: 11px;
}
span.from-user {
  font-weight: 900;
}
.date {
  font-size: 10px;
}
.body {
  color: #555555;
  font-size: 11px;
  margin-top: 10px;
}
`

const StyledMessage = styled(Message)`
height: calc(100% - 56px);
box-sizing: border-box;
`

export default StyledMessage
