import React from 'react'
import styled from 'styled-components'
import AchivementItem from '../components/AchivementItem'
import { getAchievementList } from '../api/achievementAPI'
import AddAchivDialog from './AddAchivDialog'
import ViewAchievementDialog from './ViewAchievementDialog'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Fab } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { setTotalAchievementCount } from '../store/reducers/achievement/actions'
import { Dispatch } from 'redux'
import List, { List as ListClass } from '../components/List'

interface StoreProps {
  totalCount: number;
  setTotalAchievementCount: (totalCount: number) => void;
}

interface AchivementProps extends StoreProps {
  className?: string;
}
interface AchivementStates {
  achivementList: AchivementData[];
  addDialogOpen: boolean;
  viewAcheivementDialogOpen: boolean;
  currentViewAchivItem: AchivementData | undefined;
  requestError: string;
}

export interface AchivementData {
  id: number;
  title: string;
  ts: Date;
}

class Achivement extends React.Component<AchivementProps, AchivementStates> {
  listRef: React.RefObject<ListClass>
  constructor(props: AchivementProps) {
    super(props)
    this.listRef = React.createRef()
    this.state = {
      addDialogOpen: false,
      viewAcheivementDialogOpen: false,
      achivementList: [
      ],
      requestError: '',
      currentViewAchivItem: undefined
    }
  }
  async componentDidMount() {
    this.requestData()
  }
  requestData = async () => {
    const { setTotalAchievementCount } = this.props
    const { data, total } = await getAchievementList()
    setTotalAchievementCount(total)
    const formedData = data.map(item => ({ title: item.name, ts: item.create_time, id: item.id, sticker: item.sticker }))
    return formedData
  }
  handleClickAdd = () => {
    this.setState({ addDialogOpen: !this.state.addDialogOpen })
  }
  toggleAddDialog = (refresh?: boolean) => {
    this.setState({ addDialogOpen: !this.state.addDialogOpen })
    if (refresh) this.listRef.current?.requestData()
  }
  toggleViewAchievementDialog = (data?: AchivementData) => {
    console.log('toggle')
    const { viewAcheivementDialogOpen } = this.state
    this.setState({ viewAcheivementDialogOpen: !viewAcheivementDialogOpen, currentViewAchivItem: data })
  }
  render() {
    const { className, totalCount } = this.props
    const { addDialogOpen, viewAcheivementDialogOpen, currentViewAchivItem } = this.state
    
    const renderFn = (data: any) => <AchivementItem onClick={this.toggleViewAchievementDialog.bind(this, data)} sticker={data.sticker} title={data.title} date={data.ts} key={data.id} />
    return (
      <div className={className}>
        <div className="notification">
          <section className="left">
            <VerifiedUserIcon style={{ marginRight: '5px', color: 'green' }} />
          强力完成
          <span style={{ color: 'green', padding: '0 5px' }}>{totalCount}</span>
          件小事
          </section>
          <section className="right">
            <span className="detail-statistic">查看更多统计数据</span>
          </section>
        </div>
        <div className="content">
          <List ref={this.listRef} renderFn={renderFn} requestDataFn={this.requestData} />
        </div>
        <AddAchivDialog onDismiss={this.toggleAddDialog} open={addDialogOpen} />
        <ViewAchievementDialog data={currentViewAchivItem} onDismiss={this.toggleViewAchievementDialog.bind(this)} isOpen={viewAcheivementDialogOpen} />
        <Fab onClick={this.handleClickAdd} className="float-btn" color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </div>
    )
  }
}

const StyledAchivement = styled(Achivement)`
height: calc(100% - 56px);
box-sizing: border-box;
overflow: auto;
.notification {
  height: 32px;
  background: white;
  padding: 0 17px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1101;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
  }
}
.content {
  height: calc(100% - 32px);
}
.detail-statistic {
  color: gray;
  font-size: 12px;
}
.float-btn {
  position: fixed;
  right: calc(20px + env(safe-area-inset-right));
  bottom: calc(76px + env(safe-area-inset-bottom));
}
`

const mapStateToProps = (state : any) => {
  return {
    totalCount: state.achievement.totalCount
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    setTotalAchievementCount: (totalCount: number) => dispatch(setTotalAchievementCount(totalCount))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StyledAchivement)
