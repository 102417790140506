import axios from './index'

export const login = (username: string, password: string) => {
  return axios.post('/auth/login', { username, password })
}

export const getUserInfo = () => {
  return axios.get('/user/user-info')
}

export const getUserCreditRecord = () => {
  return axios.get('/user/credit-record')
}
