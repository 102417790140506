import React from 'react'
import styled from 'styled-components'
import RedeemExhibit from '../components/RedeemExhibit'
import Crown1 from '../assets/stickers/crown1.svg'

interface RedeemProps {
  className?: string;
}

interface RedeemStates { }

class Redeem extends React.Component<RedeemProps, RedeemStates> {
  render() {
    const { className } = this.props
    return (
      <div className={className}>
        GET REDEEM HERE. Everybody love redeem.
        <RedeemExhibit name="flag(prop)" price={19} desc="Used to defined what ur gonna do"/>
        <RedeemExhibit avatar={Crown1} name="crown(sticker)" price={50} desc="Beautiful stickers(5pcs)"/>
      </div>
    )
  }
}

const StyledRedeem = styled(Redeem)`
height: calc(100% - 56px);
padding: 17px;
box-sizing: border-box;

`

export default StyledRedeem
