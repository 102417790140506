import React from 'react'
import styled from 'styled-components'
import EntryButton from '../components/EntryButton'
import { Select, MenuItem } from '@material-ui/core'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { changeLanguage } from '../store/reducers/system/actions'

interface StoreProps {
  lang: string;
  changeLanguage: (lang: string) => void;
}
interface SettingProps extends StoreProps {
  className?: string;
}

interface SettingStates {}

class Setting extends React.Component<SettingProps, SettingStates> {
  handleLangChange = (event?: any) => {
    const { changeLanguage } = this.props
    changeLanguage(event.target.value)
  }
  render () {
    const { className, lang } = this.props
    const languageSelect = (
      <Select value={lang} onChange={this.handleLangChange}>
        <MenuItem value='zh'>中文</MenuItem>
        <MenuItem value='en'>English</MenuItem>
      </Select>)
    return (
      <div className={className}>
        <div style={{ height: '20px' }}></div>
        <EntryButton displayOnly title="语言" extra={languageSelect} />
      </div>
    )
  }
}

const StyledSetting = styled(Setting)`
box-sizing: border-box;
height: calc(100% - 56px);
`
const mapStateToProps = (state: any) => {
  return {
    lang: state.system.lang
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    changeLanguage: (lang: string) => dispatch(changeLanguage(lang))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StyledSetting)
