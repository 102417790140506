import axios from './index'
import { ListData } from '../types/api'

export const getAchievementList = () => {
  return axios.get('/achievement/list') as unknown as Promise<ListData>
}

export const addAchivementItem = (name: string) => {
  return axios.post('/achievement/add', { name })
}

export const deleteAchievementItem = (id: number) => {
  return axios.post('/achievement/delete', { id })
}
