import React from 'react'
import styled from 'styled-components'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/zh-cn'
import stickerCrown1 from '../assets/stickers/crown1.svg'
dayjs.extend(relativeTime)
dayjs.locale('zh-cn')

const stickerMap: { [key: string]: string } = {
  crown1: stickerCrown1
}

interface AchivementItemProps {
  className?: string;
  title?: string;
  date?: any;
  sticker?: string;
  onClick?: () => void;
}

class AchivementItem extends React.Component<AchivementItemProps> {
  render() {
    const { className, title, date, onClick, sticker='' } = this.props
    const stickerSource = stickerMap[sticker]
    const stickerElem = stickerSource ? <img className="sticker" src={stickerSource} alt="" height="44"/> : null
    return (
      <div onClick={onClick} className={className}>
        <div className="head">
          {stickerElem}
        </div>
        <div className="left">
          <section className="title">{title}</section>
          <section style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
            <AccessTimeIcon style={{ fontSize: '12px', color: 'gray' }} />
            <span className="time">{dayjs(date).format('YYYY-MM-DD H:mm:ss')}</span>
            <span className="time" style={{ fontWeight: 900 }}>{dayjs(date).fromNow()}</span>
          </section>
        </div>
        <div className="right">
          <ArrowForwardIosIcon style={{ fontSize: '12px' }} />
        </div>
      </div>
    )
  }
}

const StyledAchivementItem = styled(AchivementItem)`
height: 80px;
box-sizing: border-box;
margin: 10px 17px;
padding: 15px;
background: white;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: space-between;
/* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.05); */
box-shadow: 0px 2px 5px -2px #9E9E9E;
.time {
  font-size: 12px;
  color: gray;
  margin-left: 8px;
}
.sticker {
  margin-right: 10px;
}
.head {
  display: flex;
  align-items: center;
}
.left {
  /* FEELS LIKE A HACK */
  min-width: 0;
  flex-grow: 1;
}
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-body-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
`
export default StyledAchivementItem
