import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { store } from './store'
store.subscribe(() => {
  i18n.changeLanguage(store.getState().system.lang)
})

const resources = {
  en: {
    translations: {
      "bottom-bar-profile": "Profile",
      "bottom-bar-flag": "Flag",
      "bottom-bar-achievement": "Achievement",
      "entry-report": "Report",
      "entry-redeem": "Redeem",
      "entry-credit-record": "Credit Record",
      "entry-message": "Message",
      "entry-setting": "Setting",
      "entry-logout": "Logout"
    }
  },
  zh: {
    translations: {
      "bottom-bar-profile": "个人",
      "bottom-bar-flag": "立旗",
      "bottom-bar-achievement": "成就",
      "entry-report": "详细报告",
      "entry-redeem": "兑换",
      "entry-credit-record": "积分记录",
      "entry-message": "消息",
      "entry-setting": "设置",
      "entry-logout": "退出登录"
    }
  }
}


i18n.use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    defaultNS: 'translations',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
