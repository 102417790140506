import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  DialogActions
} from '@material-ui/core'
import { addAchivementItem } from '../api/achievementAPI'

interface AddAchivDialogProps {
  className?: string;
  open: boolean;
  onDismiss?: (refreshData?: boolean) => void;
}

interface AddAchivDialogStates {
  achievement: string;
}

class AddAchivDialog extends React.Component<AddAchivDialogProps, AddAchivDialogStates> {

  constructor (props: AddAchivDialogProps) {
    super(props)
    this.state = {
      achievement: ''
    }
    this.handleSubmitNewAchiv = this.handleSubmitNewAchiv.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ achievement: e.target.value })
  }

  async handleSubmitNewAchiv () {
    const { achievement } = this.state
    const { onDismiss } = this.props
    if (!achievement) return
    try {
      await addAchivementItem(achievement)
      onDismiss && onDismiss(true)
    } catch (e) {}
  }

  handleCancel() {
    const { onDismiss } = this.props
    onDismiss && onDismiss()
  }

  render() {
    const { open, className } = this.props
    const { achievement } = this.state
    return (
      <Dialog className={className} open={open} aria-labelledby="form-dialog-title" fullScreen>
        {/* <DialogTitle id="form-dialog-title" className="dialog-header">
          
        </DialogTitle> */}
        <DialogContent>
          <div className="header">
            <Button onClick={this.handleCancel}>
              取消
            </Button>
            <div className="title">添加新成就</div>
            <Button disabled={achievement.length===0} onClick={this.handleSubmitNewAchiv} color="primary">
              确认
            </Button>
          </div>
          <DialogContentText>
            不积跬步无以至千里，有时候成就就是一小步一小步的进取，没有那些花里胡哨的高光.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            label="Achievement"
            multiline
            rows={6}
            variant="outlined"
            onChange={this.handleInputChange}
          />
        </DialogContent>
        <DialogActions className="footer-actions-wrapper">
        </DialogActions>
      </Dialog>
    )
  }
}
const StyledAddAchivDialog = styled(AddAchivDialog)`
.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.title {
  flex-grow: 1;
  font-size: 14px;
  text-align: center;
}
.footer-actions-wrapper {
  margin-bottom: env(safe-area-inset-bottom);
}
`
export default StyledAddAchivDialog
