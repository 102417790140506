import axios from 'axios'
import { store } from '../store'
import { showGlobalNavbar } from '../store/reducers/system/actions'
import { logout } from '../store/reducers/user/actions'

const instance = axios.create({
  // baseURL: 'http://localhost:3000'
  baseURL: 'https://ican-api.dzmtoast.top'
})

instance.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
  return config
})

instance.interceptors.response.use((res) => {
  console.log('resp intercept')
  if (res.status === 200 || res.status === 201) {
    return res.data
  } else if (res.status === 401) {
  } else {
    return res
  }
}, err => {
  if (401 === err.response.status) {
    store.dispatch(showGlobalNavbar({ message: '请登录' }))
    store.dispatch(logout())
    console.log('login plz')
  }
  return Promise.reject(err)
})

export default instance
