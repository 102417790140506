import { combineReducers } from 'redux'
import userReducer from './user'
import systemReducer from './system'
import achievementReducer from './achievement'

export default combineReducers({
  user: userReducer,
  system: systemReducer,
  achievement: achievementReducer
})
